*,
html,
body,
a,
p,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Poppins", sans-serif;
}
p {
  font-size: 16px;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 0.03em;
  text-align: left;
}
.btn__main {
  background: #442255 !important;
  color: #fff;
  border-color: #442255 !important;
  border-radius: 0 !important;
  font-size: 15px !important;
  line-height: 22.5px;
  font-weight: 400;
  padding: 10px 35px !important;
}
.container-fluid {
  padding: 0 120px !important;
}
.Nav__wrapper {
  position: relative;
  z-index: 999;
}
.Nav__wrapper .navbar-nav a {
  padding: 0 20px !important;
  color: #fff !important;
}
.hp__banner {
  position: relative;
  top: -90px;
  margin-bottom: -90px;
}
.hp__banner img {
  width: 100%;
}
.quopte__wrapper {
  background: #fff;
  width: 450px;
  position: absolute;
  top: 20%;
  left: 10%;
}
.quopte__wrapper h2 {
  background: #442255;
  text-align: center;
  color: #fff;
  font-size: 36px;
  line-height: 50px;
  padding: 25px 0;
  font-weight: 600;
}
.hp__banner .banner__text {
  position: absolute;
  top: 30%;
  right: 5%;
  width: 46%;
}
.hp__banner .banner__text h1 {
  font-size: 70px;
  color: #fff;
  font-weight: 800;
  text-transform: uppercase;
}
.hp__banner .banner__text h1 span {
  color: #ffddaa;
}
.section__gap {
  padding: 80px 0;
}
.purple__text {
  color: #442255;
}
.section__title {
  font-size: 55px;
  font-weight: 700;
  line-height: 83px;
  letter-spacing: 0em;
  color: #442255;
}
.section__title__with__underline {
  color: #442255;
  font-size: 55px;
  font-weight: 700;
  line-height: 83px;
  letter-spacing: 0em;
  text-align: center;
  position: relative;
  margin-bottom: 80px;
  display: inline-block;
}
.pt-0 {
  padding-top: 0;
}
.section__title__with__underline:after {
  content: "";
  position: absolute;
  width: 115%;
  height: 15px;
  left: 50%;
  bottom: 0;
  transform: translate(-50%, -50%);
  background: #ffddaa;
  transform: translate(-50%, -50%) rotate(-2.39deg);
  z-index: -1;
}

.threecolwithimage__wrapper .threecolwithimage__unit {
  text-align: center;
}
.threecolwithimage__wrapper .threecolwithimage__unit img {
  width: 350px;
  height: 120px;
  object-fit: contain;
  margin-bottom: 30px;
}

.threecolwithimage__wrapper .threecolwithimage__unit h4 {
  font-size: 20px;
  font-weight: 500;
  line-height: 40px;
  letter-spacing: 0em;
  text-transform: uppercase;
}

.threecolwithimage__wrapper .threecolwithimage__unit p {
  text-align: center;
  font-size: 13px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
}
.custom__media {
  display: flex;
  justify-content: space-between;
  gap: 30px;
  height: 120px;
  padding: 10px;
  align-items: center;
}
.custom__media .left__part {
  background-color: #f7e8ff;
  padding: 20px;
  border-radius: 8px;
}
.custom__media .left__part img {
  width: 40px;
}
.custom__media .right__part p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}

.custom__media:hover {
  box-shadow: 0px 20px 9px -14px #80808061;
  transition: 0.6s all ease;
}
.custom__media:hover .left__part {
  background-color: #442255;
  transition: 0.6s all ease;
}
.custom__media:hover .left__part img {
  filter: brightness(0) invert(1);
  transition: 0.6s all ease;
}

.custom__media__plain {
  display: flex;
  justify-content: start;
  gap: 30px;
  height: 120px;
  padding: 10px;
  align-items: center;
}
.custom__media__plain .left__part {
  padding: 20px;
  border-radius: 8px;
}
.custom__media__plain .left__part img {
  width: 50px;
}
.custom__media__plain .right__part h3 {
  font-size: 20px;
  font-weight: 800;
  line-height: 40px;
  letter-spacing: 0em;
  text-align: left;
  color: #3a3a3a;
}
.custom__media__plain .right__part p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  margin-bottom: 0;
}
.homepage-review .reviewer-info small {
  display: block;
}
.homepage-review .reviewer-info {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  gap: 30px;
}
.homepage-review .reviewer-info img {
  width: 80px !important;
}

.homepage-review p {
  text-align: center;
}
.homepage-review .reviewitem {
  width: 80%;
  margin: 0 auto;
  background: #f7e8ff;
  padding: 40px;
  border-radius: 16px;
}
.carousel-status {
  display: none;
}

.control-dots {
  bottom: -50px !important;
}
.carousel.carousel-slider {
  overflow: visible !important;
}
.carousel .control-dots .dot {
  width: 15px !important;
  height: 15px !important;
  opacity: 1 !important;
  background: #f7e8ff !important;
}
.homepage-review .slick-dots li button:before {
  font-size: 17px;
}
.homepage-review .slick-dots li.slick-active button:before {
  font-size: 17px;
  color: #442255;
  width: 15px !important;
  height: 15px !important;
  opacity: 1 !important;
}
.homepage-review .slick-slider {
  width: 60%;
  margin: 0 auto;
}
.homepage-review .slick-prev:before {
  content: "\f104";
  font-family: "FontAwesome";
  color: #442255;opacity: 1;
  font-size: 36px;
}
.homepage-review .slick-next:before {
  content: "\f105";
  font-family: "FontAwesome";
  font-size: 36px;
  color: #442255;opacity: 1;
}
.homepage-review .slick-prev {
  left: -55px;
}
.slick-next {
  right: -55px;
}
footer {
  background: #1d1d1d;
  padding-top: 55px;
  padding-bottom: 10px;
  color: #fff;
}
footer img {
  margin-bottom: 20px;
}
footer p {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
}

footer h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
  letter-spacing: 0em;
  text-align: left;
}
footer .list-group-item {
  background-color: transparent;
  padding: 0;
  border: 0;
}
footer .list-group-item a {
  color: #fff;
  text-decoration: none;
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
}
footer .input-group {
  height: 50px;
}
footer .input-group-text {
  background: #fcfcfc;
  border: 0;
  border-radius: 10px;
}
footer .form-control {
  border: 0;
  border-radius: 10px;
  background: #fcfcfc;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
}
footer .input-group-text i {
  color: #828282;
}
footer .copyright {
  text-align: center;
  color: #d3d3d3;
  padding-bottom: 0;
  margin-bottom: 0;
}
.quopte__wrapper form {
  padding: 20px;
}
.quopte__wrapper .form-select,
.quopte__wrapper .form-control {
  border: 0;
  border-radius: 0px;
  background: #ececec;
  font-size: 14px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
  height: 55px;
}

.quopte__wrapper .input-group-text {
  border: 0;
  border-radius: 0px;
}
.quopte__wrapper .input-group-text i {
  color: #828282;
}
.btn__gradient {
  border: 0 !important;
  height: 55px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;

  background: linear-gradient(
    90.97deg,
    #ffcd83 0.17%,
    rgba(68, 34, 85, 0.67) 100%
  ) !important;
}
.service__list .col-lg-4.col:last-child {
  position: relative;
  margin: 0 auto;
}
.inr__banner {
  position: relative;
  top: -100px;
  margin-bottom: -100px;
}
.inr__banner img {
  width: 100%;
}
.small__title {
  color: #aa5566;
  font-size: 18px;
  font-weight: 800;
}
.diamond-list .list-group-item {
  border: 0 !important;
  position: relative;
  padding-left: 20px !important;
}
.diamond-list .list-group-item:before {
  content: "";
  width: 7px;
  height: 7px;
  background-color: #aa5566;
  position: absolute;
  left: 0;
  top: 17px;
  transform: rotate(45deg);
}

.service__area__list i {
  color: #616161;
  padding-right: 10px;
}

.box-list-inline {
  display: block !important;
  flex-direction: row !important;
  gap: 30px;
}
.box-list-inline .list-group-item {
  width: 23%;
  margin: 15px 10px;
  float: left;
  text-align: left;
  border: 0;
  box-shadow: 0 0 10px 1px #e5e5e5;
  padding: 20px;
  border-radius: 5px !important;
  border-top: 4px solid #aa5566;
  border-top-width: 4px !important;
}
.bg-dim {
  background: #48106417;
}

.threecolwithimage__wrapper
  .threecolwithimage__unit
  .mob__fleet__info
  .fa-star {
  color: #fbb509;
  font-size: 18px;
}
.threecolwithimage__wrapper
  .threecolwithimage__unit
  .mob__fleet__info
  .fa-user {
  color: #181818;
  font-size: 18px;
}
.threecolwithimage__wrapper .threecolwithimage__unit .mob__fleet__info {
  display: flex;
  justify-content: space-evenly;
  gap: 50px;
  margin-bottom: 30px;
  color: #7b7b7b;
}
.contact-form {
  background: #442255;
  padding: 25px;
  border-radius: 15px;
}
.contact-form .btn__main {
  width: 100%;
  border: 1px solid #ffcd83 !important;
}
.contact-form input {
  height: 55px;
}

.contact-form h3 {
  color: #fff;
  font-size: 20px !important;
  margin-bottom: 20px;
}

.contact__page p {
  margin-bottom: 5px;
}
.contact__page a {
  color: #442255;
  text-decoration: none;
  font-weight: 800;
}
.mob__nav {
  display: none;
}
.mob__nav a {
  color: #fff;
  text-decoration: none;
  font-size: 20px;
}

.sidenav {
  height: 100%;
  width: 250px;
  position: fixed;
  z-index: 1;
  top: 0;
  left: -250px;
  background: #101422;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  z-index: 999999999999;
}

.sidenav a {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 18px;
  color: #fff;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.close {
  color: #fff;
}
.mob__fleet__info {
  display: none;
}
.sidenav a.active {
  color: #ffe12e;
}
.mob__nav p {
  margin-bottom: 0 !important;
}
.mob__nav {
  position: relative;
  z-index: 999;
  width: 100%; 
  justify-content: space-between;
  padding: 20px 40px;
}
.mob__nav .open__nav {
  background: #442255;
  width: 40px;
  height: 40px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  color: #fff;
}
.close__nav i {
  font-size: 24px;
  margin-right: 10px;
}
.close__nav {
  display: flex;
  color: #fff;
  font-size: 16px;
  padding-left: 35px;
  margin-bottom: 20px;
}
footer h4 {
  margin-top: 30px;
}
footer .social-icons a {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 50%;
  margin: 0 10px;
  height: 50px;
  color: #fff;
  width: 50px;
  display: inline-block;
}
footer .social-icons i {
  font-size: 24px;
}
.fleet-page{
  padding-top: 80px;
}