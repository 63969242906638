@media (max-width: 1416px) {
  .hp__banner .banner__text h1 {
    font-size: 48px;
  }
  .section__title {
    font-size: 35px;
    line-height: 44px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit img {
    width: 100%;
  }
  .hp__banner img {
    height: 900px;
    object-fit: cover;
  }
  .quopte__wrapper img {
    height: auto;
  }
  .section__title__with__underline {
    font-size: 36px;
  }
  .threecolwithimage__wrapper {
    padding-top: 0;
  }
}

@media (max-width: 1199px) {
  .container-fluid {
    padding: 0 20px !important;
  }
  .quopte__wrapper {
    top: 15%;
    left: 3%;
  }
  .hp__banner .banner__text {
    right: 3%;
    width: 44%;
  }
  .hp__banner .banner__text h1 {
    font-size: 36px;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    width: 50%;
  }
  .mob__app__wrapper img {
    width: 60%;
  }
  .section__title {
    font-size: 28px;
  }
  .custom__media__plain .left__part {
    padding: 0;
  }
  .box-list-inline .list-group-item {
    width: 30%;
  }
}

@media (max-width: 991px) {
  nav.navbar {
    display: none;
  }
  .mob__nav {
    display: flex;
    align-items: center;
  }
  .hp__banner {
    top: -110px;
  }
  .hp__banner .banner__text h1 {
    margin-bottom: 24px;
  }
  .hp__banner .banner__text {
    left: 40px;
    top: 170px;
    width: 85%;
    text-align: left;
    right: 0;
  }
  .quopte__wrapper {
    top: 35%;
    left: 5%;
    width: 90%;
    position: absolute;
  }
  .quopte__wrapper h2 {
    font-size: 26px;
  }
  .hp__banner img {
    height: 1100px;
  }
  .quopte__wrapper img {
    height: auto;
  }
  .twocol__image img {
    width: 100%;
    height: 400px;
    object-fit: contain;
    margin-bottom: 40px;
  }
  .section__title {
    font-size: 22px;
    line-height: 30px;
  }
  .mob__app__wrapper .mt-5 {
    margin-top: 0 !important;
  }
  .section__title__with__underline {
    font-size: 23px;
  }
}

@media (max-width: 767px) {
  .hp__banner .banner__text h1 {
    font-size: 32px;
    font-weight: 700;
    line-height: 56px;
  }
  .mob__app__wrapper .text-center {
    text-align: left !important;
  }
  .mob__app__wrapper img {
    width: 100%;
    height: 300px;
    object-fit: contain;
    margin-bottom: 20px;
  }

  .homepage-review .slick-slider {
    width: 80%;
  }
  .hp__banner .banner__text {
    width: 80%;
  }
  .box-list-inline .list-group-item {
    width: 45%;
  }
  .service__area__list p{
    text-align: left !important;
  }
  .contact-form{
    margin-top: 40px;
  }
}

@media (max-width: 576px) {
  .section__title__with__underline {
    font-size: 23px;
    line-height: 34px;
  }
  .section__title__with__underline:after {
    content: none;
  }
  .threecolwithimage__wrapper .threecolwithimage__unit {
    width: 100%;
  }
  .homepage-review .slick-slider {
    width: 100%;
  }

  .custom__media__plain {
    height: 150px;
  }
  .box-list-inline .list-group-item {
    width: 95%;
  }
}

@media (max-width: 480px) {
  .custom__media__plain img {
    height: auto;
    width: 80px !important;
  }
  .custom__media__plain .right__part h3,
  .custom__media__plain .right__part p {
    text-align: center;
  }
  .custom__media__plain {
    height: auto;
    text-align: center;
    display: block;
  }
  .homepage-review .reviewer-info {
    display: block;
    text-align: center;
  }
  .homepage-review .reviewer-info img {
    width: 80px !important;
    margin: 0 auto;
  }
  .homepage-review .reviewitem {
    padding: 20px;
    margin-bottom: 40px;
  }
  .hp__banner .banner__text h1 {
    font-size: 30px;
    font-weight: 700;
    line-height: 44px;
  }
  .hp__banner .banner__text {
    top: 130px;
  }
}
